<template>
    <section class="crear-tipo-anuncio ml-4">
        <!-- breadcrumb -->
        <div class="row my-4">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right f-15">
                    <el-breadcrumb-item :to="{ name: 'configurar.tipo.anuncios' }">
                        Configuración de Tipos de anuncios
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-if="!hasId()">
                        Nuevo tipo
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-else>
                        Guardar tipo
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <!-- Titulo -->
        <div class="row my-4">
            <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                <div class="row">
                    <div class="col-auto">
                        <p v-if="hasId()" class="f-22 f-500">Editar tipo de anuncio</p>
                        <p v-else class="f-22 f-500">Nuevo tipo de anuncio</p>
                    </div>
                    <div class="col-auto ml-auto">
                        <p class="f-22 f-500">{{ tipo.cantUsos }} Usos</p>
                    </div>
                    <div v-if="hasId()" class="col-auto">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                            <div slot="content" class="cr-pointer" @click="eliminarTipoAnuncio">
                                <p class="f-14">Eliminar</p>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <ValidationObserver ref="form" tag="div">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-2">
                    <ValidationProvider v-slot="{errors}" tag="div" rules="required|max:30" name="nombre">
                        <p class="f-12 pl-2 label-inputs">Nombre del tipo</p>
                        <el-input v-model="tipo.nombre" placeholder="Nombre" size="small" maxlength="30" show-word-limit />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-md-5 col-sm-6 col-lg-4 col-xl-3 mb-2">
                    <ValidationProvider v-slot="{errors}" tag="div" rules="required|numeric|max_value:100" name="Días">
                        <p class="f-12 pl-2 label-inputs">Días de vigencia por defecto</p>
                        <el-input v-model="tipo.vigencia" placeholder="Ingrese el número de días" size="small" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <el-checkbox :value="dinero" @change="toggleDinero">
                        Tipo de anuncio con valor
                    </el-checkbox>
                </div>
            </div>
            <!-- iconos --> 
            <p class="f-15 mb-2 mt-4">Ícono</p>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                    <div class="row mx-0 lista-iconos">
                        <div v-for="data in listadoIconos" :key="data.i" 
                             class="outer-circle rounded-circle mr-2 d-middle-center"
                             :class="tipo.icono == data.icono ? 'selected':''" 
                             @click="seleccionarIcono(data.icono)"
                        >
                            <div class="inner-circle d-middle-center rounded-circle cr-pointer" 
                                 :style="`background: ${tipo.color != '' ? tipo.color : '#dbdbdb'}`"
                            >
                                <i :class="`${data.size} op-06 ${data.icono ? data.icono : ''}`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- colores --> 
            <p class="f-15 mb-2 mt-4">Color</p>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                    <div class="row mx-0 lista-iconos">
                        <div v-for="data in listadoBackgrounds" :key="data.i" 
                             class="outer-circle rounded-circle mr-2 d-middle-center"
                             :class="tipo.color == data.background ? 'selected':''" 
                             @click="seleccionarBackground(data.background)"
                        >
                            <div class="inner-circle d-middle-center rounded-circle cr-pointer" :style="`background: ${data.background}`" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- btn guardar -->
            <div class="row mt-2 mt-sm-4">
                <div class="col-12">
                    <button class="btn btn-general py-1 f-14 f-300 px-3" @click="guardar">
                        Guardar
                    </button>
                </div>
            </div>
        </ValidationObserver>
        <!-- partials -->
        <modal-eliminar ref="abrirEliminarTipoAnuncio" titulo="Eliminar tipo de anuncio" mensaje="¿Desea eliminar este tipo de anuncio?" align-text="text-center" @eliminar="eliminar" />
        <modal-eliminar ref="bloqueoEliminarTipoAnuncio" titulo="Eliminar tipo de anuncio" mensaje="No es posible eliminar este tipo de anuncio, está siendo usado" align-text="text-center" :btn-confirm="false" :btn-cancel="false" />
    </section>
</template>
<script>
import Categorias from '~/services/carteleras/categorias'
export default {
    data(){
        return{
            editar: true,
            listadoIconos:[
                {
                    id:0,
                    size: 'f-16',
                    icono: 'icon-shop-outline',
                },
                {
                    id:1,
                    size: 'f-16',
                    icono: 'icon-like',
                },
                {
                    id:2,
                    size: 'f-14',
                    icono: 'icon-book',
                },
                {
                    id:3,
                    size: 'f-16',
                    icono: 'icon-help-circle-outline',
                },
                {
                    id:4,
                    size: 'f-16',
                    icono: 'icon-tools',
                },
                {
                    id:5,
                    size: 'f-16',
                    icono: 'icon-car',
                },
                {
                    id:6,
                    size: 'f-16',
                    icono: 'icon-producto',
                },
                {
                    id:7,
                    size: 'f-16',
                    icono: 'icon-heart',
                },
                {
                    id:8,
                    size: 'f-16',
                    icono: 'icon-document',
                },
                {
                    id:9,
                    size: 'f-16',
                    icono: 'icon-me_interesa',
                },
                {
                    id:10,
                    size: 'f-16',
                    icono: 'icon-pool',
                },
                {
                    id:11,
                    size: 'f-16',
                    icono: 'icon-alerta_2',
                },
                {
                    id: 12,
                    size: 'f-16',
                    icono: 'icon-calendar'
                },
                {
                    id: 13,
                    size: 'f-14',
                    icono: 'icon-cash'
                },
                {
                    id: 14,
                    size: 'f-16',
                    icono: 'icon-forks'
                }
            ],
            listadoBackgrounds:[
                {
                    id:0,
                    background: '#FFD54F',
                },
                {
                    id:1,
                    background: '#B2B2FF',
                },
                {
                    id:2,
                    background: '#93EDCF',
                },
                {
                    id:3,
                    background: '#7684f1',
                },
                {
                    id:4,
                    background: '#FFB2C2',
                },
                {
                    id:5,
                    background: '#B7ED93',
                },
                {
                    id:6,
                    background: '#80DCFF',
                },
                {
                    id:7,
                    background: '#FF9680',
                },
                {
                    id:8,
                    background: '#FF94F1',
                },
                {
                    id:9,
                    background: '#B2D9FF',
                },
                {
                    id:10,
                    background: '#FFC992',
                },
            ],

            tipo:{
                id: null,
                nombre : "",
                vigencia : 7,
                dinero : 0,
                icono : "",
                color : "",
                cantUsos: 0
            },

        }        
    },
    computed:{
        dinero(){
            return (this.tipo.dinero == 1)
        }
    },
    async created(){
        if(this.hasId()){
            await this.obternerTipo(this.$route.params.id_tipo)
        }
        if(this.$route.query.existente === null){
            this.tipo.id = null
        }
    },
    methods:{
        seleccionarIcono(icono){
            this.tipo.icono = icono
        },
        seleccionarBackground(bg){
            this.tipo.color = bg
        },
        eliminarTipoAnuncio(){
            if (this.tipo.cantUsos > 0){
                this.$refs.bloqueoEliminarTipoAnuncio.toggle();
            } else {
                this.$refs.abrirEliminarTipoAnuncio.toggle();
            }
        },
        toggleDinero(d){
            this.tipo.dinero = d ? 1 : 0 
        },
        async guardar(){
            try {
                let validate = await this.$refs.form.validate()
                if(!validate) return this.notificacion('','Por favor completa los campos')
                if(!this.tipo.icono) return this.notificacion('','Por favor selecciona un icono')
                if(!this.tipo.color) return this.notificacion('','Por favor selecciona un color')

                this.loading = true
                let payload={
                    nombre : this.tipo.nombre,
                    vigencia : this.tipo.vigencia,
                    dinero : this.tipo.dinero,
                    icono : this.tipo.icono,
                    color : this.tipo.color
                }
                if(this.tipo.id != null){
                    payload.id = this.tipo.id
                }
                const {data} = await Categorias.guardar(payload)
                this.tipos = data.data
                this.loading = false

                if(data.success){
                    this.$router.push({ name: 'configurar.tipo.anuncios'})
                }
            
            } catch (e){
                console.log(e)
                this.loading = false
            }          
        },
        async obternerTipo(id){
            try {
                this.loading = true            
                const {data} = await Categorias.obtenerTipo(id)
                this.tipo = data.data
                this.loading = false            
            } catch (e){
                this.errorCatch(e)
                this.loading = false
            }          
        },
        async eliminar(){
            try {
                this.loading = true            
                const {data} = await Categorias.eliminar(this.tipo.id)
            
                this.loading = false

                if(data.success){
                    this.$refs.abrirEliminarTipoAnuncio.toggle()
                    this.$router.push({ name: 'configurar.tipo.anuncios'})
                }
            } catch (e){
                console.log(e)
                this.loading = false
            }          
        },
        hasId(){
            return (typeof this.$route.params.id_tipo != 'undefined')
        }

    }
}
</script>
<style lang="scss" scoped>
.crear-tipo-anuncio{
    $width-outer: 44px;
    $height-outer: 44px;
    $width-inner: 36px;
    $height-inner: 36px;
    .lista-iconos{
        .outer-circle{
            max-width: $width-outer;
            min-width: $width-outer;
            max-height: $height-outer;
            min-height: $height-outer;
            &.selected{
                border: 1.5px solid var(--color-86);
            }

        }
        .inner-circle{
            max-width: $width-inner;
            min-width: $width-inner;
            max-height: $height-inner;
            min-height: $height-inner;

        }
    }
    i.icon-book{
        &::before{
            margin-left: 0;
        }
    }
    i.icon-cash{
        &::before{
            margin-left: 0;
        }
    }
}
</style>